<template>
  <div class="mortgagegold" @click="isDo()">
    <van-nav-bar
      title="存料"
      safe-area-inset-top
      fixed
      left-arrow
      placeholder
      :border="false"
      @click-left="onClickLeft"
    />
    <!-- 实时金价 -->
    <div class="rel">
      <center class="boxgoldprice">
        <h2>{{ pricebox }}</h2>
        <div class="pricebox">{{ initCity }}</div>
      </center>
      <div class="bottombox">
        <div class="userselect">
          <van-cell title="订单类型" :value="initCity" size="large" />
          <van-cell title="快递费" :value="userInfo.expressFee" size="large" />
          <van-cell
            title="保险费"
            :value="userInfo.insuranceFee"
            size="large"
          />
          <van-cell
            title="提纯费"
            :value="userInfo.purificationFee"
            size="large"
          />
          <div class="title_box ">
            <div class="flex-between weightbox">
              <span
                >重量
                <i class="dingJIn"
                  >(可存克重
                  <span class="dingJinData">{{
                    userInfo.goldIncomingFreeWeight
                  }}</span>
                  )</i
                ></span
              >
              <el-input
                v-model="weight"
                placeholder="0"
                type="number"
                @input="handleInput2"
                maxlength="6"
                :class="isinput ? 'yellowfont' : ''"
              ></el-input>
              <div class="unit">克</div>
            </div>
          </div>
<!--          <div class="title_box">
            <van-radio-group
              v-model="radioMini"
              direction="horizontal"
              @change="radioChangeMini"
            >
              <van-radio name="one" icon-size="16" checked-color="red"
                >100g</van-radio
              >
              <van-radio name="two" icon-size="16" checked-color="red"
                >200g</van-radio
              >
              <van-radio name="three" icon-size="16" checked-color="red"
                >300g</van-radio
              >
              <van-radio name="five" icon-size="16" checked-color="red"
                >500g</van-radio
              >
            </van-radio-group>
          </div>-->
          <div class="title_box ">
            <div class="flex-between weightbox">
              <span>预收金额</span>
              <div
                class="countMumber"
                :style="{ color: isinput ? '#242424' : '#D1D1D1' }"
              >
                {{ countMumber }}
              </div>
              <div class="unit">元</div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="btnbox"
        :style="{ background: Number(weight) ? '#ff2d2e' : '#f7cdcd' }"
        @click="postData('showpop')"
      >
        立即存料
      </div>
      <div class="tipBox">
        <div>
          温馨提示
          <div class="leftLine"></div>
          <div class="rightLine"></div>
        </div>
        <div v-html="mortgageTips"></div>
      </div>
      <van-dialog v-model="showorder">
        <div class="title">订单确认</div>
        <div class="confirmCenterbox_dialog">
          <div class="flex orderInfo">
            <div class="orderCol">订单类型</div>
            <div>{{ initCity }}实物</div>
          </div>
          <div class="flex orderInfo">
            <div class="orderCol">重量</div>
            <div>{{ weight }}g</div>
          </div>
          <div class="flex orderInfo">
            <div class="orderCol">初始单价</div>
            <div>
              <span style="color:#FF4B71">{{ pricebox }}</span> 元/克
            </div>
          </div>
          <div class="flex orderInfo">
            <div class="orderCol">预收金额</div>
            <div>{{ countMumber }}元</div>
          </div>
        </div>
        <!-- <div class="text">温馨提示:{{sellTips}}.</div> -->
        <div class="autograph">
          <div class="autographText">手写签名</div>
          <div class="autographBox">
            <img v-if="img" :src="img" alt="" />
          </div>
        </div>
        <div class="flex popbtn">
          <div class="gocer closebtn" @click="closepop">取消</div>
          <div class="gocer confirmbtn" @click.stop="postData('postDataf')">
            确定
          </div>
        </div>
      </van-dialog>
      <van-dialog v-model="ceringModel" class="ceringModel">
        <div class="title">提示</div>
        <div class="centerbox_dialog">{{ stopMiniTrading }}</div>
        <div class="confirm" @click="ceringModel = false">我知道了</div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Stomp from "stompjs";
import * as api from "@/utils/api";
import Decimal from "decimal.js";
import * as PriceLineChart from "@/utils/api";
import { getGoods, getMortageSettings, statementCommon } from "@/utils/api";

export default {
  data() {
    return {
      showorder: false, // 存料确认是否显示
      initDatalist: [], // 首次加载的数据
      firstloadData: true, // 是否第一次加载
      ceringModel: false, // 停盘说明是否显示
      isclick: false, // 防止重复提交
      secondClick: false, // 第二次重复提交
      isinput: false, // 是否输入状态
      countMumber: "0", // 预收金额
      allpro: [], // 所有商品数组
      pricebox: "--.--",
      priceId: "", // 当前价格id
      initCity: "黄金",
      weight: "", // 存料克重
      trade_: false, // 开停盘状态
      client: {}, // websocket连接
      img: "", // 手写签名
      mortgageTips: "", // 存料说明
      userInfo: {}, // 用户信息
      miniOrderTime: [], // 小克重下单时间
      stopMiniTrading: "", // 小克重停盘说明
      radioMini: "", // 快捷存料数字
      lastTime: null, //最后一次点击的时间
      currentTime: null, //当前点击的时间
      timeOut: 30 * 1000, //设置超时时间： 30s
      mortgageSettings: {
        commission: 85,
        remind: 8,
        rental: 0,
        prepay: 85,
        forceClose: 5
      }, // 存料配置
      goods: {} // 商品信息
    };
  },
  async created() {
    const res = await api.getDefaultExplain({ title: "" });
    res.forEach(item => {
      if (item.title === "mortgageTips") {
        this.mortgageTips = item.content;
      } else if (item.title === "stopTradingSamllDescription") {
        this.stopMiniTrading = item.content;
      }
    });
    api.getMiniOrderTime().then(res => {
      this.miniOrderTime = res.orderTime;
    });
    api.getMortageSettings().then(res => {
      console.log(res);
      this.mortgageSettings = res;
    });
    api.getGoods("MORTGAGE", { statementFlag: true }).then(res => {
      const resFilter = res.filter(function(item, index) {
        return item.name.includes("黄金");
      });
      if (resFilter.length) {
        this.goods = resFilter[0];
      } else {
        this.goods = res[0];
      }
    });
    this.showorder = false;
    this.lastTime = new Date().getTime(); // 网页第一次打开时，记录当前时间
  },
  mounted() {
    this.pricebox = this.$route.query.price;
    this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
    this.img = this.userInfo.sign;
    this.connect();
  },
  watch: {
    allpro: function() {
      if (this.initCity != "请选择类别") {
        this.updatearr(this.initCity);
      }
    },
    weight(newval, oldVal) {
      if (!newval) {
        this.countMumber = 0;
        this.isinput = false;
      } else {
        if (newval > this.userInfo.goldIncomingFreeWeight) {
          this.weight = "";
          this.radioMini = "";
          this.$toast("可用折足克重不足!");
        }
      }
    }
  },
  destroyed() {
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    });
  },
  methods: {
    onClickLeft() {
      this.$router.push({
        name: "pricing"
      });
      this.weight = "";
    },
    closepop() {
      this.showorder = false;
      this.isclick = false;
      this.secondClick = false;
    },
    connect() {
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onConnected() {
      const topic = "/price/all";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      if (!this.client.connected) {
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    deepMerge(...objs) {
      const than = this;
      const result = Object.create(null);
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val);
              } else {
                result[key] = than.deepMerge(val);
              }
            } else {
              //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val];
              } else {
                result[key] = val;
              }
            }
          });
        }
      });
      return result;
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString;
      return toString.call(val) === "[object Object]";
    },
    responseCallback(frame) {
      this.allpro = [];
      const resbody = eval("[" + this.unzip(frame.body) + "]")[0];
      const parse_res = JSON.parse(JSON.stringify(resbody));
      this.trade_ = resbody.trade;
      this.priceId = resbody.id;
      if (this.firstloadData) {
        this.initDatalist = resbody;
        this.firstloadData = false;
        for (const i in this.initDatalist) {
          if (this.initDatalist[i]["prices"] != undefined) {
            this.allpro.push(this.initDatalist[i]["prices"]);
          }
        }
      } else {
        this.initDatalist = this.deepMerge(this.initDatalist, parse_res);
        for (const i in this.initDatalist) {
          if (this.initDatalist[i]["prices"] != undefined) {
            this.allpro.push(this.initDatalist[i]["prices"]);
          }
        }
      }
    },
    // 价格变动更新视图
    pricenew(name) {
      this.allpro.find(x => {
        if (x[name]) {
          this.pricebox = x[name]["huigou"];
        }
      });
    },
    // 更新数组
    updatearr(name) {
      if (name == "黄金") {
        this.pricenew("huangjin9999");
        this.productkey = "huangjin9999";
      }
    },
    radioChangeMini(name) {
      this.weight =
        this.radioMini === "one"
          ? 100
          : this.radioMini === "two"
          ? 200
          : this.radioMini === "three"
          ? 300
          : this.radioMini === "five"
          ? 500
          : this.weight || "";
      if (this.radioMini) {
        this.handleInputMini("");
      }
    },
    handleInputMini(e) {
      this.isclick = false;
      this.isinput = true;
      this.computerMoney();
    },
    handleInput2(e) {
      this.radioMini = "";
      if (this.weight == 0) {
        this.weight = "";
        return !1;
      } else {
        this.isclick = false;
        this.isinput = true;
        this.weight = this.weight.match(/^\d*(\.?\d{0,2})/g)[0] || null;
        this.computerMoney();
      }
    },
    computerMoney() {
      const money = new Decimal(+this.weight)
        .mul(this.pricebox)
        .sub(new Decimal(this.userInfo.expressFee))
        .sub(new Decimal(this.userInfo.insuranceFee))
        .sub(new Decimal(this.userInfo.purificationFee));
      this.countMumber = Number(
        money.mul(new Decimal(this.mortgageSettings.prepay)).div(new Decimal('100')).toFixed(0, Decimal.ROUND_HALF_UP)
      );
    },
    isDo() {
      this.lastTime = new Date().getTime(); //如果在30钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
    },
    timeSwitch(date1) {
      const nowTime1 = new Date();
      nowTime1.setHours(date1.getHours());
      nowTime1.setMinutes(date1.getMinutes());
      nowTime1.setSeconds(date1.getSeconds());
      return nowTime1;
    },
    postData(type) {
      this.currentTime = new Date().getTime(); // 记录这次点击的时间
      //判断上次最后一次点击的时间和这次点击的时间间隔是否大于10分钟
      if (this.currentTime - this.lastTime > this.timeOut) {
        // 这里写状态已过期后执行的操作
        this.$toast({
          type: "fail",
          message: "当前页面停留时间过长",
          forbidClick: true,
          onClose: () => {
            this.showorder = false;
            this.lastTime = new Date().getTime();
          }
        });
      } else {
        this.lastTime = new Date().getTime(); //如果在30钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
        if (type == "postDataf") {
          if (this.secondClick) {
            setTimeout(() => {
              this.secondClick = false;
            }, 1000);
            return false;
          }
          this.secondClick = true;
          const postWeight = Number(this.weight);
          const postPrice = this.pricebox.toString();
          const postData = {
            baiyinReferenceWeight: 0,
            expressMoney: this.userInfo.expressFee,
            goldReferenceWeight: postWeight,
            initPrice: postPrice,
            insFee: this.userInfo.insuranceFee,
            items: [
              {
                afterWeight: postWeight,
                discountWeight: postWeight,
                extra: {
                  orderWeight: postWeight
                },
                fromPlatform: "MOBILE_PWA",
                goods: {
                  id: this.goods.id,
                  key: this.goods.key,
                  name: this.goods.name
                },
                percent: this.goods.percent,
                priceId: this.priceId,
                money: Number(
                  new Decimal(this.weight)
                    .mul(new Decimal(this.pricebox))
                    .toFixed(2, Decimal.ROUND_FLOOR)
                ),
                price: postPrice,
                sort: 0,
                statementOrderType: "MORTGAGE",
                storageFee: 0,
                unfinishStorageFee: 0,
                weight: postWeight
              }
            ],
            money: this.countMumber,
            mortgageWeight: postWeight,
            orderList: [],
            pdReferenceWeight: 0,
            ptReferenceWeight: 0,
            storageFee: 0,
            totalDiscountWeight: postWeight,
            totalWeight: postWeight,
            userId: this.userInfo.uid,
            purificationFee: this.userInfo.purificationFee,
            prepaidPercent: this.mortgageSettings.prepay,
            remindPercent: this.mortgageSettings.remind,
            warehouse: this.mortgageSettings.rental
          };
          api
            .statementCommon("PRE_MORTGAGE", postData)
            .then(res => {
              this.weight = "";
              this.radioMini = "";
              this.showorder = false;
              this.secondClick = false;
              this.isinput = false;
              this.$router.push({
                name: "successpage",
                query: { type: "mortgage" }
              });
            })
            .catch(err => {});
        } else {
          if (this.isclick) {
            setTimeout(() => {
              this.isclick = false;
            }, 1000);
            return false;
          }
          this.isclick = true;
          if (!this.trade_) {
            this.ceringModel = true;
            this.isclick = false;
            return false;
          }
          const nowTime = new Date();
          const date1 = new Date(this.miniOrderTime[0].start * 1000);
          const date2 = new Date(this.miniOrderTime[0].end * 1000);
          const date3 = new Date(this.miniOrderTime[1].start * 1000);
          const date4 = new Date(this.miniOrderTime[1].end * 1000);
          const nowTime1 = this.timeSwitch(date1);
          const nowTime2 = this.timeSwitch(date2);
          const nowTime3 = this.timeSwitch(date3);
          const nowTime4 = this.timeSwitch(date4);
          if (
            (nowTime.getTime() - nowTime1.getTime() > 0 &&
              nowTime.getTime() - nowTime2.getTime() < 0) ||
            (nowTime.getTime() - nowTime3.getTime() > 0 &&
              nowTime.getTime() - nowTime4.getTime() < 0)
          ) {
            if (!Number(this.weight)) {
              return this.$toast("克重不能为空"), !1;
            }
            if (Number(this.weight) > this.userInfo.goldIncomingFreeWeight) {
              return this.$toast("可用折足克重不足!"), !1;
            }
            if (this.userInfo.goldIncomingFreeWeight >= 50 && Number(this.weight) < 50) {
              this.isclick = false;
              return this.$toast("单次存料50g起!"), !1;
            } else if (this.userInfo.goldIncomingFreeWeight < 50 && this.userInfo.goldIncomingFreeWeight !== Number(this.weight)) {
              this.isclick = false;
              return this.$toast("50g以下需一次存料!"), !1;
            }
            this.showorder = true;
            this.isclick = false;
          } else {
            this.ceringModel = true;
            return false;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped="true">
::v-deep {
  .van-nav-bar {
    height: 100px;
    .van-nav-bar__content {
      line-height: 100px;
      height: 100px;
      background-color: #fff;
      .van-nav-bar__title {
        font-size: 36px;
        color: #242424;
      }
      .van-icon {
        font-size: 40px;
        color: #242424;
      }
    }
  }
  .van-radio-group--horizontal {
    justify-content: space-around;
    height: 60px;
  }
}
::v-deep {
  .van-cell:after {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  .van-cell {
    padding: 30px 20px 0px;

    .van-cell__title {
      font-size: 36px;
      color: #666666;
      line-height: 50px;
    }
    .van-cell__value {
      font-size: 36px;
      color: #242424;
      line-height: 50px;
    }
    .van-icon {
      font-size: 36px;
      color: #9d9d9d;
      line-height: 50px;
    }
  }
  .van-picker__columns {
    display: block;
  }
  //.van-popup.van-popup--round.van-popup--bottom.van-popup--safe-area-inset-bottom.van-action-sheet{
  //  height: 300px;
  //}
  //.van-action-sheet{
  //  height: 400px !important;
  //}
  .van-action-sheet__item {
    line-height: 80px;
    font-size: 32px;
  }
  .van-action-sheet__cancel,
  .van-action-sheet__item {
    font-size: 32px;
  }
}
.dingJIn {
  font-style: normal;
  font-size: 24px;
  .dingJinData {
    font-size: 24px;
    color: red;
  }
}
.tipboxback {
  color: #f2aa2e;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 5px 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -25px;
  &:after {
    content: "";
    width: 0;
    height: 0;
    left: 125px;
    bottom: -12px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid rgba(0, 0, 0, 0.8);
  }
}
.tipboxcarid {
  font-style: normal;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #f2aa2e;
  width: 30px;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  color: #f2aa2e;
  border-radius: 50%;
  display: inline-block;
}
.popbtn {
  margin-top: 25px;
  div {
    margin-top: 5px !important;
    border-top: none !important;
    width: 210px;
    text-align: center;
    border-radius: 50px;
  }
}
.gocer {
  margin: 0 auto;
  width: 280px;
  height: 72px;
  font-size: 28px;
  line-height: 72px;
  margin-top: 30px;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  background-color: #ff2d2e;
}
.centerbox_dialog {
  text-align: left;
  padding: 0 50px;
  color: #666666;
  // padding: 0 10px;
  font-size: 28px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.confirmCenterbox_dialog {
  text-align: left;
  padding-bottom: 30px;
  font-size: 28px;
}
.text {
  font-size: 20px;
  color: #666666;
  line-height: 38px;
  margin: 30px 0;
}
::v-deep .van-dialog {
  width: 90%;
  padding: 0 40px 30px;
  .title {
    color: #242424;
  }
}
::v-deep .van-button {
  display: none;
}
.loadigboxdetals {
  height: 140px;
  .van-loading {
    padding-top: 200px;
  }
}
.Loading {
  position: absolute;
  top: 34%;
  left: 50%;
  margin-left: -40px;
}
.title {
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.userselect {
  background: #fff;
  padding: 10px 0 40px;
  border-radius: 4px 4px 4px 4px;
  // background: red;
}
.countMumber {
  color: #d1d1d1;
  margin-right: 50px;
  margin-top: 12px;
  font-size: 36px;
}
.countAvailableMoney {
  color: #d1d1d1;
  margin-right: 50px;
  margin-top: 12px;
  font-size: 36px;
}
::v-deep .van-loading__spinner .van-loading__spinner--circular {
  font-size: 80px;
}
.centerboxloading {
  font-size: 80px;
}
.bottombox {
  padding: 0 30px 20px;
}
.citybox {
  color: #cccccc;
  font-size: 40px;
  margin: 0 30px;
}
.drepcolor {
  color: #292929;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
::v-deep .van-picker__confirm {
  font-size: 32px;
}
::v-deep .van-picker__columns .van-picker-column:nth-child(2) {
  display: none;
}
::v-deep .van-picker-column__item {
  font-size: 30px;
}
::v-deep .el-input__inner {
  border: 0;
  height: auto;
  font-size: 40px;
  padding: 0;
  text-align: right;
}
::v-deep .el-input {
  width: 30%;
  margin-right: 50px;
  margin-top: 4px;
}
::v-deep .el-input__inner::placeholder {
  color: #cccccc;
}
.weightbox {
  border-bottom: 1px solid #e0e0e0;
  padding: 4px 0 22px;
  span {
    padding: 10px 0px;
  }
  .zuiDi {
    font-size: 22px;
    color: #d1d1d1;
  }
}
.unit {
  position: absolute;
  top: 30%;
  right: 28px;
  color: #242424;
  font-size: 40px;
}
.title_box {
  position: relative;
  margin: 0 !important;
  padding: 28px 18px 0 !important;
  span {
    font-size: 36px;
    color: #666666;
  }
  .textTip {
    font-size: 20px;
    color: #666666;
    line-height: 34px;
  }
}
.moneybox {
  padding: 0 30px;
  font-size: 40px;
  color: #cccccc;
  b {
    font-weight: normal;
  }
}
.title_box {
  font-size: 28px;
  color: #292929;
  margin: 0 30px;
  color: #292929;
  padding: 30px 0 30px;
}
.btnbox {
  width: 690px;
  background: #ff2d2e;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  margin-top: 30px;
  line-height: 88px;
  border-radius: 50px;
}
.tipBox {
  padding: 60px 30px 70px;
  color: #999999;
  font-size: 24px;
  :nth-child(1) {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    .leftLine {
      width: 80px;
      border: 1px solid #999999;
      position: absolute;
      top: 16px;
      left: 30%;
    }
    .rightLine {
      width: 80px;
      border: 1px solid #999999;
      position: absolute;
      top: 16px;
      right: 30%;
    }
  }
  div {
    line-height: 38px;
    margin-bottom: 10px;
  }
}
.yellowbox {
  text-align: center;
  background: #feae14;
  width: 750px;
  height: 200px;
  border-radius: 0 0 10% 10%;
}
.yellowfont {
  color: #feae14 !important;
}
.yellowfont ::v-deep .el-input__inner {
  color: #feae14 !important;
  background-color: #ffffff !important;
}
.rel {
  background: #f5f5f5;
}
.boxgoldprice {
  // height: 200px;
  overflow: hidden;
  width: 700px;
  margin: 30px auto;
  background: url("~@/assets/images/pic_sell_price@2x.png") no-repeat;
  background-size: 100% 100%;
  h2 {
    color: #ff4b71;
    font-weight: 600;
    font-size: 44px;
    margin-top: 34px;
  }
  .pricebox {
    margin-top: 0px;
    font-size: 30px;
    color: #242424;
    margin: 21px 0 30px;
  }
}
.closebtn {
  background-color: #d1d1d1;
}
.confirmbtn {
  background-color: #ff2d2e;
}
.confirm {
  text-align: center;
  height: 40px;
  color: #ff2d2e;
  margin-top: 40px;
  font-size: 28px;
  line-height: 40px;
}
._leftbox {
  padding-left: 10px;
}
.mortgagegold {
  background-color: #f5f5f5;
  padding-bottom: 40px;
  height: 100%;
}
.orderInfo {
  padding: 12px 0;
  .orderCol {
    color: #999999;
  }
}
.autograph {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  // box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  .autographText {
    width: 100%;
    height: 56px;
    line-height: 56px;
    color: #fff;
    background-color: #5473ff;
    font-size: 24px;
  }
  .autographBox {
    height: 200px;
    border: 1px solid #e9e9e9;
    img {
      width: 200px;
      height: 100%;
      transform: rotate(270deg);
    }
  }
}
._leftbox {
  padding: 0 !important;
}
.ceringModel {
  width: 74%;
  .centerbox_dialog {
    padding: 0 0 30px !important;
    border-bottom: 1px solid #e9e9e9;
  }
  .confirm {
    margin: 30px 0 0;
  }
}
</style>
